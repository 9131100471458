<script setup lang="ts">
import ProfileHeader from '@/views/profile/components/ProfileHeader.vue';
import { useProfileView } from '@/views/profile/composables';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ProfileNavigations from '@/views/profile/components/ProfileNavigations.vue';
import AppColorMode from '@/views/profile/components/AppColorMode.vue';

const {
	profileConfigNavsVisible,
	profileConfigStatsVisible,
	isProfileStatsLoaded,
	isLoading,
	isDarkModeEnabled,
} = useProfileView();
</script>

<template>
	<main class="profile" :class="{ 'page-flex': isLoading }">
		<PrimeLoaderSpinner v-if="isLoading" />
		<template v-else>
			<ProfileHeader />
			<ProfileNavigations
				:visible="isProfileStatsLoaded"
				:nav-items="profileConfigNavsVisible"
				:stats-items="profileConfigStatsVisible"
			/>
			<AppColorMode v-if="isDarkModeEnabled" />
		</template>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.profile {
	width: 100%;
	margin-top: 1.125rem;

	&.page-flex .page-card {
		display: flex;
	}

	:deep(.p-loader-spinner) {
		height: 100%;
	}

	@include breakpoint(mobile) {
		margin-top: 2.25rem;
	}
}
</style>
